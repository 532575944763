import React, { useEffect, useState } from "react";
import {
  Create as BaseCreate,
  SimpleForm,
  SelectInput,
  required,
  useGetOne,
  ReferenceInput,
  useChoicesContext,
  useCreate,
  TextInput,
  usePermissions,
} from "react-admin";
import moment from "moment";
import { es } from "date-fns/locale";
import { useFormContext, useWatch } from "react-hook-form";
import { DateInput } from "../components/DatePicker";
import { useSearchParams } from "react-router-dom";
import { ProductCreate } from "../components/product";

const _DateInput = ({ availability, source, label, ...props }) => {
  return (
    <DateInput
      validate={[required()]}
      source={source}
      label={label}
      inputVariant="outlined"
      options={{
        format: "dd/MM/YYY",
        clearable: true,
        disablePast: true,
      }}
      providerOptions={{ locale: es }}
      shouldDisableDate={(day) => {
        const date = moment(day);
        return (
          date < moment().startOf("day") ||
          availability[date.isoWeekday() - 1].length === 0
        );
      }}
      {...props}
    />
  );
};

const _HourInput = ({ source, dateSource, label }) => {
  const { watch, setValue } = useFormContext();
  const date = watch(dateSource);
  const [hourPicker, setHourPicker] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { permissions } = usePermissions();
  const profile = JSON.parse(localStorage.getItem("profile"));
  const providerId = permissions !== "admin-sucursal" ? profile._id : profile.employer._id;
  console.log("providerId", providerId);

  useEffect(() => {
    if (searchParams.get("date")) {
      setValue(dateSource, moment(searchParams.get("date")));
    }
  }, [searchParams.get(dateSource)]);

  useEffect(() => {
    _setHourPicker(moment(date));
    setValue(source, "");
  }, [date]);

  const [fetchAvailability, { isLoading, error }] = useCreate();

  const _setHourPicker = async (date) => {
    let array = [];
    let today = moment();
    let isSameDay = moment(today).isSame(date, "day");
    await fetchAvailability(
      "dateAvailabilityWithoutBranch/" + providerId,
      {
        data: {
          date: date,
          estimatedTime: 1,
        },
      },
      {
        onSuccess: (response) => {
          response.data.availability.map((e) => {
            const _date = moment(e);
            if (isSameDay && _date.hours() <= today.hours()) {
              return;
            }
            array.push({
              id: _date.hours() + _date.minutes() / 60,
              name: _date.format("HH:mm"),
            });
          });
          setHourPicker(array);
        },
      }
    );
  };

  return (
    <SelectInput
      validate={[required()]}
      source={source}
      label={label}
      choices={hourPicker}
      disabled={hourPicker.length === 0}
    />
  );
};

export const Create = (props) => {
  const initAvailability = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  };
  const [availability, setAvailability] = useState(initAvailability);
  const { permissions } = usePermissions();
  const profile = JSON.parse(localStorage.getItem("profile"));
  const {
    data: user,
    isLoading,
    error,
  } = useGetOne("availability", {
    id: permissions !== "admin-sucursal" ? profile._id : profile.employer._id,
  });
  
  useEffect(() => {
    if (user?.availability) {
      const _availability = user?.availability?.reduce((array, e) => {
        return {
          ...array,
          [e.dayIndex]: [...array[e.dayIndex], e],
        };
      }, initAvailability);
      setAvailability(_availability);
    }
  }, [user]);

  const transform = (data) => {
    const provider = permissions !== "admin-sucursal" ? profile : profile.employer;
    console.log("provider", provider);
    const unavailability = {
      ...data,
      startDate: moment(data.startDate)
        .hours(data.startHour)
        .minutes((data.startHour % 1) * 60)
        .format(),

      endDate: moment(data.startDate)
        .hours(data.endHour)
        .minutes((data.endHour % 1) * 60)
        .format(),
      provider: {
        ...provider,
        name: provider.first_name,
      },
    };
    if (data.employee) {
      unavailability.employee = {
        ...data.employee,
        fullName: data.employee.first_name + " " + data.employee.last_name,
        CI: data.employee.CI_NIT,
      };
    }
    return unavailability;
  };
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <BaseCreate
      {...props}
      transform={transform}
      redirect={(resource, id, data, state) => {
        const redirectParam = searchParams.get("redirect")
          ? searchParams.get("redirect") + "?date=" + data.startDate
          : null;
        return redirectParam ?? "unavailability";
      }}
    >
      <SimpleForm>
        <_DateInput
          availability={availability}
          source="startDate"
          label="Fecha de Inicio"
        />
        <br />
        <_HourInput
          availability={availability}
          source="startHour"
          dateSource="startDate"
          label="Hora Inicial"
        />
        <_HourInput
          availability={availability}
          source="endHour"
          dateSource="startDate"
          label="Hora Final"
        />
        <ReferenceEmployee />
        <TextInput source="reason" label="Comentario" />
      </SimpleForm>
    </BaseCreate>
  );
};

const SelectEmployeeInput = ({ source, ...props }) => {
  const choicesContext = useChoicesContext();
  return (
    <SelectInput
      {...props}
      label="Empleado"
      optionText={(value) => {
        return value.first_name + " " + value.last_name;
      }}
      optionValue="_id"
      // disabled={!date || !hour}
      parse={(value) => {
        return choicesContext?.allChoices.find((e) => e._id === value);
      }}
      format={(value) => {
        return value?._id;
      }}
    />
  );
};

const ReferenceEmployee = () => {
  const { permissions } = usePermissions();
  const profile = JSON.parse(localStorage.getItem("profile"));
  const providerId = permissions !== "admin-sucursal" ? profile._id : profile.employer._id;  
  return (
    <ReferenceInput
      label="Empleado"
      source="employee"
      reference="employee"
      filter={{
        idProv: providerId,
        state: true,
      }}
    >
      <SelectEmployeeInput />
    </ReferenceInput>
  );
};
