import React from 'react';
import { styled } from '@mui/material/styles';
import { useShowController, TabbedForm, FormTab, NumberInput, SelectInput, Edit, TextInput, SimpleForm, required, ArrayInput, SimpleFormIterator, PasswordInput, TextField, ImageField, ImageInput, useEditController, Loading } from 'react-admin';
import { FormDataConsumer } from 'react-admin';
const PREFIX = 'AvailabilityEdit';

const classes = {
    inlineBlock: `${PREFIX}-inlineBlock`
};

const StyledEdit  = styled(Edit )({
    [`& .${classes.inlineBlock}`]: { display: 'inline-flex', marginRight: '1rem', maxWidth:160 },
});

export const hours = [
    { id: 0, name: '00' },
    { id: 1, name: '01' },
    { id: 2, name: '02' },
    { id: 3, name: '03' },
    { id: 4, name: '04' },
    { id: 5, name: '05' },
    { id: 6, name: '06' },
    { id: 7, name: '07' },
    { id: 8, name: '08' },
    { id: 9, name: '09' },
    { id: 10, name: '10' },
    { id: 11, name: '11' },
    { id: 12, name: '12' },
    { id: 13, name: '13' },
    { id: 14, name: '14' },
    { id: 15, name: '15' },
    { id: 16, name: '16' },
    { id: 17, name: '17' },
    { id: 18, name: '18' },
    { id: 19, name: '19' },
    { id: 20, name: '20' },
    { id: 21, name: '21' },
    { id: 22, name: '22' },
    { id: 23, name: '23' },
]

export const getFinalFor = (initial) => {
    if (initial)
        return hours.filter(e => e.id > initial)
    return hours
}

export const getInitialFor = (final) => {
    if (final)
        return hours.filter(e => e.id < final)
    return hours
}

export const AvailabilityEdit = (({  ...props }) => {
    const controllerProps = useEditController(props); // Hook de react-admin
    const { isLoading } = controllerProps;

    if (isLoading) {
        return <Loading />; // Muestra el spinner mientras se cargan los datos
    }    
    return (
        <Edit
            // I don't need any redirection here, there's no list page
            {...props}
            id={0}
            resource="availabilityWeb"//"availability"
            basePath="/availabilityWeb"
            redirect={false}
            title="Disponibilidad"
        >
            <TabbedForm>
                <FormTab label="Disponibilidad">
                    <ArrayInput validate={[required()]} source="availability" label="Disponibilidad"><SimpleFormIterator>
                        <SelectInput validate={[required()]} source="day" label="Día" choices={[
                            { id: 'L', name: 'Lunes' },
                            { id: 'M', name: 'Martes' },
                            { id: 'MM', name: 'Miércoles' },
                            { id: 'J', name: 'Jueves' },
                            { id: 'V', name: 'Viernes' },
                            { id: 'S', name: 'Sábado' },
                            { id: 'D', name: 'Domingo' },
                        ]} />


                        <FormDataConsumer formClassName={classes.inlineBlock}>
                            {({ formData, // The whole form data
                                scopedFormData, // The data for this item of the ArrayInput
                                getSource, // A function to get the valid source inside an ArrayInput
                                ...rest }) =>

                                <SelectInput validate={[required()]} source={getSource('startHour')} label="Hora de Inicio"
                                  //  onChange={(e) => { console.log(e) }}
                                    choices={getInitialFor(scopedFormData?.endHour)} />
                            }
                        </FormDataConsumer>
                        <SelectInput validate={[required()]} source="startMinute" label="Minuto de inicio" formClassName={classes.inlineBlock} choices={[
                            { id: '0', name: '00' },
                            { id: '30', name: '30' },

                        ]} />

                        <FormDataConsumer formClassName={classes.inlineBlock}>
                            {({ formData, // The whole form data
                                scopedFormData, // The data for this item of the ArrayInput
                                getSource, // A function to get the valid source inside an ArrayInput
                                ...rest }) =>

                                <SelectInput validate={[required()]} source={getSource('endHour')} label="Hora Final" //formClassName={classes.inlineBlock}
                                    choices={getFinalFor(scopedFormData?.startHour)} formClassName={classes.inlineBlock} />
                            }
                        </FormDataConsumer>
                        {/* <SelectInput validate={[required()]} source="endHour" label="Hora Final" formClassName={classes.inlineBlock}
                            choices={finalHour} /> */}
                        <SelectInput validate={[required()]} source="endMinute" label="Minuto final" formClassName={classes.inlineBlock} choices={[
                            { id: '0', name: '00' },
                            { id: '30', name: '30' },

                        ]} />
                    </SimpleFormIterator></ArrayInput>
                </FormTab>
            </TabbedForm >

        </Edit >
    );
}
)
