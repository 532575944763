import React, { useCallback, useEffect, useState } from 'react';
import { useListContext, Datagrid, TextField, FunctionField, DateField, Loading } from 'react-admin';
import { CircularProgress, Link } from '@mui/material';
import moment from 'moment';
import { useIntersectionObserver } from './useIntersectionObserver';
import debounce from 'just-debounce-it';
const WhatsAppField = ({ record }) => {
  if (!record || !record.phone) return null;
  return (
    <a href={`https://wa.me/${record.countryCode}${record.phone}`} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "black" }}>
      {record.phone}
    </a>
  );
};

const InfiniteListLayout = ({ setTotalCount, setTotalPages }) => {
  const {
    data,
    total,
    page,
    setPage,
    isLoading,
    hasNextPage
  } = useListContext();
  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, []);
  const [allRecords, setAllRecords] = useState([]);
  
  useEffect(() => {
    if (!data) return;
    if (page === 1) {
      setAllRecords(data);
    } else {
      setAllRecords(prev => {
        const existingIds = new Set(prev.map(r => r.id));
        const newRecords = data.filter(r => !existingIds.has(r.id));
        const combined = [...prev, ...newRecords];
        return combined;
      });
    }
    setTotalCount(total);
  }, [ page, data ]);

  setTotalPages(allRecords.length);
  const canLoadMore = allRecords.length < total;
  const [sentinelRef, isIntersecting] = useIntersectionObserver({
    rootMargin: '0px',
    threshold: 0.1,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceHandleNextPage = useCallback(debounce(
    () => setPage(page+1), 1000
  ), [setPage])
  useEffect(() => {
    if (isIntersecting && canLoadMore && hasNextPage) {
      debounceHandleNextPage()
    }
  }, [isIntersecting, canLoadMore, debounceHandleNextPage, hasNextPage]);
  
  return (
    <div style={{ height: 'calc(100vh - 200px)', overflow: 'auto' }}>
      <Datagrid
        bulkActionButtons={false}
        data={allRecords}
        isLoading={isLoading}
        rowClick="edit"
      >
        <TextField source="fullName" label="Nombre Completo" />
        <FunctionField label="Teléfono" render={(record) => <WhatsAppField record={record} />} />
        <TextField source="email" label="Email" />
        <TextField source="gender" label="Género" />
        <DateField source="birthdate" label="Fecha de Nacimiento" />
        <FunctionField
          label="Fecha de Ultimo servicio"
          sortBy="lastBooking.bookingDate"
          render={(record) => {
            const date = record.lastBooking
              ? moment(record.lastBooking.bookingDate)
              : null;
            return record.lastBooking?.bookingDate ? (
              <Link href={"#/booking/" + record.lastBooking._id + "/show"}>
                {date.format("DD/MM/YYYY")}
              </Link>
            ) : (
              ""
            );
          }}
        />
        <FunctionField
          label="Dias desde el ultimo servicio"
          sortBy="lastBooking.bookingDate"
          render={(record) => {
            const diff = record.lastBooking
              ? moment().diff(moment(record.lastBooking.bookingDate), "days")
              : null;
            return record.lastBooking?.bookingDate ? diff : "";
          }}
        />
      </Datagrid>

      {isLoading && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Loading />
        </div>
      )}

      {!isLoading && !canLoadMore && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <p>Todos los clientes han sido cargados.</p>
        </div>
      )}
      
      {canLoadMore && !isLoading && hasNextPage &&(
        <div
          ref={sentinelRef} 
          style={{ border: '1px solid transparent', padding: 0, marginTop: 0 }}
        />
      )}
      
      {canLoadMore && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <CircularProgress />
        </div>     
      )}
    </div>
  );
};

export default InfiniteListLayout;
