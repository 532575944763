import { Box, CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";
import React, { useState } from "react";
import {  
  List as BaseList,
  Datagrid,
  TextField,
  DateInput,
  DateField,
  TopToolbar,
  FilterButton,
  CreateButton,
  useListContext,
} from "react-admin";
import useExport from "../components/UseExcelExport";
import ExportButton from "../components/ExcelExportButton";
import moment from "moment";

const UnavailabilityFilter = [
  <DateInput label="Desde" source="startDate>" />,
  <DateInput label="Hasta" source="startDate<" />
]

const ListActions = (props) => {
  const { getExportData, exportData, loading, setExportData } = useExport("unavailability");
  const [openModal, setOpenModal] = useState(false);
  const { filterValues } = useListContext();

  const handleExportClick = async () => {
    if (!exportData.length) {
      setOpenModal(true);
      await getExportData(filterValues);
      setOpenModal(false);
    }
  };

  const handleClearData = () => {
    setExportData([]);
  };
  const { className, basePath } = props;

  const columns = [
    { label: "Fecha de Inicio", source: "startDate", transform: (val) => moment(val).format("YYYY-MM-DD") },
    { label: "Fecha Final", source: "endDate", transform: (val) => moment(val).format("YYYY-MM-DD") },
    { label: "Empleado", source: "employee.fullName" },
    { label: "Borrado", source: "deletedAt", transform: (val) => moment(val).format("YYYY-MM-DD") },
  ];

  return (
    <TopToolbar
      className={className}
      style={{
        display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
        padding: "0 16px",
        marginBottom: "0",
      }}
    >
     <div style={{ display: "flex", gap: "16px" }}>
        <FilterButton filters={UnavailabilityFilter} />
        <CreateButton basePath={basePath} />
     </div>
      <ExportButton onClick={handleExportClick} data={exportData} columns={columns} filename="Horarios No Disponibles.xlsx" clearData={handleClearData}/>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Typography variant="body1" style={{ marginTop: 16 }}>
              Cargando datos para exportación...
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </TopToolbar>
  );
};

export const List = (props) => (
  <BaseList
    sort={{ field: "startDate", order: "DESC" }}
    filters={UnavailabilityFilter}
    bulkActionButtons={false}
    {...props}
    actions={<ListActions />}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <DateField
        source="startDate"
        label="Fecha de Inicio"
        showTime
        locales="es-ES"
      />
      <DateField
        source="endDate"
        label="Fecha Final"
        showTime
        locales="es-ES"
      />
      <TextField source="employee.fullName" label="Empleado" />
      <DateField source="deletedAt" label="Borrado" showTime locales="es-ES" />
    </Datagrid>
  </BaseList>
);
