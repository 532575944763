import React, { useState } from "react";
import {
  List as BaseList,
  CreateButton,
  TopToolbar,
  useNotify,
  FilterButton,
  usePermissions,
  Filter,
  useListContext,
} from "react-admin";
import RegexSearchInput from "../../components/RegexSearchInput";
import moment from "moment";
import DownloadCsvButton from "../../components/downloadCsv";
import { ImportButton } from "react-admin-import-csv";
import { Box, CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";
import ExportButton from "../../components/ExcelExportButton";
import useExport from "../../components/UseExcelExport";
import InfiniteListLayout from "./components/MyInfiniteList";


const NameFilter = [
  <RegexSearchInput source="phone" label="Teléfono" />

];

const Filters = (props) => (
  <Filter {...props}>
    {/* Usar RegexSearchInput para nombre y teléfono */}
    <RegexSearchInput source="fullName" label="Buscar por Nombre" alwaysOn />
    <RegexSearchInput source="phone" label="Teléfono" />
  </Filter>
);


const ListActions = (props) => {
  const { getExportData, exportData, setExportData } = useExport("enterprise/customers");
  const [openModal, setOpenModal ] = useState(false);
  const { permissions } = usePermissions();
  const { filterValues } = useListContext();

  const handleExportClick = async () => {
    if (!exportData.length) {      
      setOpenModal(true);
      await getExportData(filterValues); 
      setOpenModal(false)
    }
  };
  const handleClearData = () => {
    setExportData([]);
  };
  const {
    className,
    basePath,
  } = props;
  const notify = useNotify();
  const [errors, setErrors] = useState([]);
  const errorsToString = (errors) => {
    return errors.reduce((acc, error) => {
      return `${acc ? acc + "\n" : ""}${error}`;
    }, "");
  };

  const columns = [
    { label: "Nombre Completo", source: "fullName" },
    { label: "Teléfono", source: "phone" },
    { label: "Email", source: "email" },    
    { label: "Fecha de Nacimiento", source: "birthdate", transform: (val) => moment(val).format("DD/MM/YYYY") },
    
  ];

  return (
    <TopToolbar
      className={className}
      style={{
        display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
        padding: "0 16px",
        marginBottom: "0",
      }}
    >
      <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
        <FilterButton filters={NameFilter} />
        <CreateButton basePath={basePath} />
      </div>
      {permissions !== "admin-sucursal" && <ExportButton onClick={handleExportClick} data={exportData} columns={columns} filename="customers.xlsx" clearData={handleClearData} />}
      <div style={{ display: "flex", gap: "16px" }}>
        { permissions !== "admin-sucursal" &&
          <ImportButton
          {...props}
          disableImportOverwrite={true}
          transformRows={(csvRows) => {
            return csvRows.map((row) => {
              const transformedRow = {};
              transformedRow.fullName = row.Nombre;
              if (row.Telefono) transformedRow.phone = row.Telefono;
              if (row.Email) transformedRow.email = row.Email;
              transformedRow.addresses = [];
              return transformedRow;
            });
          }}
          validateRow={async (csvRowItem, row) => {
            if (row === 0) {
              setErrors([]);
            }
            if (!csvRowItem.fullName) {
              errors.push(`Fila ${row + 1}: Nombre es requerido`);
              notify(errorsToString(errors), { type: "error", multiLine: true });
              throw new Error("Nombre es requerido");
            }
            if (csvRowItem.phone && isNaN(csvRowItem.phone)) {
              errors.push(`Fila ${row + 1}: Telefono debe ser un numero`);
              notify(errorsToString(errors), { type: "error", multiLine: true });
              throw new Error("Telefono debe ser un numero");
            }
            if (csvRowItem.email && !csvRowItem.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
              errors.push(`Fila ${row + 1}: Email invalido`);
              notify(errorsToString(errors), { type: "error", multiLine: true });
              throw new Error("Email invalido");
            }
          }}
        />
        }
        {permissions === "admin-sucursal" && <DownloadCsvButton />}
      </div>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Typography variant="body1" style={{ marginTop: 16 }}>
              Cargando datos para exportación...
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </TopToolbar>
  );
};


export const List = (props) => {
  const { permissions } = usePermissions();
  const profile = JSON.parse(localStorage.getItem("profile"));
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  return (
    <div>
      <BaseList
        pagination={false}
        perPage={20}
        actions={<ListActions />}
        filters={<Filters />}
        filter={permissions !== "admin-sucursal" ? null : { providerId: profile.employer._id }}
        {...props}
      >
        <InfiniteListLayout setTotalCount={setTotalCount} setTotalPages={setTotalPages}/>
      </BaseList>
      <Box display="flex" justifyContent="flex-end" alignItems="center" padding="16px">
        <Typography variant="body1">
          {totalPages} de {totalCount} clientes
        </Typography>
      </Box>
    </div>
  );
};
