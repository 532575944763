import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { GET_ONE, UPDATE } from "react-admin";
import Resizer from "react-image-file-resizer";

const apiUrl = process.env.REACT_APP_API_URL;
const httpClient = (url, options = {}) => {
  let token = localStorage.getItem("token");
  options.user = {
    authenticated: true,
    token: "Bearer " + token,
  };
  
  return fetchUtils
    .fetchJson(url, options)
    .then(({ headers, json }) => {
      return { headers, json };
    })
    .catch((error) => {
      console.error("Error en la solicitud:", error);
      throw error;
    });

};

export const getResizedImage = (file, max, format) =>
  new Promise((resolve) => {
    return Resizer.imageFileResizer(
      file,
      max,
      max,
      format,
      90,
      0,
      (blob) => {
        return resolve(blob);
      },
      "blob"
    );
  });

const getList = (resource, params = {}) => {
  const query = params?.filter ?? {};
  const { pagination, sort } = params;
  query.page = pagination?.page ?? null;
  query.perPage = pagination?.perPage ?? null;
  query.sort = (sort?.order === "DESC" ? "-" : "") + sort?.field;
  if (query.provider?.name) {
    query["provider.name"] = query.provider.name;
    delete query.provider;
  }

  const url = `${apiUrl}/${resource}?${stringify(query)}`;
  return httpClient(url, {
    method: "GET",
  }).then(({ headers, json }) => {
    const data = json.data;
    return {
      data: Array.isArray(data)
        ? data.map((e) => {
            e.id = e._id;
            return e;
          })
        : [{ ...data, id: 1 }],
      total: json.totalDocs ?? 1,
      totalAmount: json.totalAmount ?? 0,
      totalFeeAmount: json.totalFeeAmount ?? 0,
    };
  });
};

const getOne = (resource, params) => {
  console.log("getOne", `${apiUrl}/${resource}/${params.id}`);
  return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
    return {
      data: {
        ...(json.data ?? json),
        id: params.id ?? json._id ?? json.data?._id,
      },  
    };
  });
};

const getMany = (resource, params) => {
  const query = {
    filter: JSON.stringify({ id: params.ids }),
  };
  const url = `${apiUrl}/${resource}`;
  return httpClient(url).then(({ json }) => {
    return {
      data: json.data.map((e) => {
        e.id = e._id;
        return e;
      }),
    };
  });
};

const create = (resource, params) => {
  console.log(resource)
  console.log(params.data)
  return httpClient(`${apiUrl}/${resource}`, {
    method: "POST",
    body: JSON.stringify(params.data),
  })
    .then(({ json }) => {
      if (json.error) {
        return Promise.reject(json.error);
      }
      return {
        data: {
          ...params.data,
          id: json.data?._id || json._id,
          ...json,
        },
      };
    })
    .catch((error) => Promise.reject(error));
};

const createMany = async (resource, params) => {
  try {
    const response = await httpClient(`${apiUrl}/${resource}/bulk`, {
      method: "POST",
      body: JSON.stringify(params.data),
    });

    return {
      data: response.json.map((record) => ({ ...record, id: record._id })),
    };
  } catch (error) {
    throw new Error(error.message || "Error al crear registros");
  }
};

export const createDataForm = async (resource, dataWithFile) => {
  return await httpClient(`${apiUrl}/${resource}`, {
    method: "POST",
    mode: "cors",
    body: dataWithFile,
  })
    .then(({ json }) => {
      if (json.error) {
        return Promise.reject(json.error);
      }
      json.data.id = json.data._id;
      return {
        data: json.data,
      };
    })
    .catch((error) => Promise.reject(error));
};

const objectToFormData = (object) =>
  Object.keys(object)?.reduce((formData, key) => {
    const value =
      typeof object[key] === "object" && object[key] !== null
        ? JSON.stringify(object[key])
        : object[key];
    formData.append(key, value);
    return formData;
  }, new FormData());

const update = (resource, params) => {
  const baseUrl = `${apiUrl}/${resource}/${params.id ?? ""}${
    params.meta?.customAction ? "/" + params.meta.customAction : ""
  }`;
  return httpClient(baseUrl, {
    method: "PUT",
    body: JSON.stringify(params.data),
  })
    .then(({ json }) => {
      if (json.error) {
        return Promise.reject(json.error);
      }
      return { data: { ...json.data, id: json.data?._id ?? json._id } };
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const updateDataForm = (resource, dataWithFile, id) => {
  return httpClient(`${apiUrl}/${resource}/${id}`, {
    method: "PUT",
    mode: "cors",
    body: dataWithFile,
  }).then(({ json }) => ({ data: { ...json.data, id: json.data._id } }));
};

const _delete = (resource, params) => {
  return httpClient(`${apiUrl}/${resource}/${params.id}`, {
    method: "DELETE",
  }).then(({ json }) => ({ data: json.data }));
};

const handler = async (verb, resource, params) => {
  if (resource === "categories") {
    if (verb === "CREATE") {
      const dataWithFile = new FormData();
      dataWithFile.append("name", params.data.name);
      dataWithFile.append("type", params.data.type);
      dataWithFile.append(
        "logo",
        await getResizedImage(params.data.logo.rawFile, 200, "PNG")
          .then((mediaFile) => {
            return mediaFile;
          })
          .catch((e) => {            
          })
      );

      const resultArray = await Promise.all(
        params.data.subCategory.map(async (e) => {
          if (e.name && e.logo) {
            dataWithFile.append("subCategory.name", e.name);
            const resizedImg = await getResizedImage(e.logo.rawFile, 400, "PNG")
              .then((mediaFile) => {
                return mediaFile;
              })
              .catch((e) => {                
              });
            dataWithFile.append("subCategory.logo", await resizedImg);
          } else {
            Promise.reject("Debes llenar todos los campos de la subcategoría");
          }
        })
      );
      return await createDataForm(resource, dataWithFile);
    }
    if (verb === "UPDATE") {
      const dataWithFile = new FormData();
      dataWithFile.append("name", params.data.name);
      dataWithFile.append("state", params.data.state);
      dataWithFile.append(
        "logo",
        await getResizedImage(params.data.logo.rawFile, 200, "PNG")
          .then((mediaFile) => {
            return mediaFile;
          })
          .catch((e) => {            
          })
      );
      const resultArray = await Promise.all(
        params.data.subCategory.map(async (e) => {
          if (e.name) {
            dataWithFile.append("subCategory.name", e.name);
            dataWithFile.append("subCategory.state", e.state);
            if (typeof e.logo != "string") {
              dataWithFile.append(
                "subCategory.logo",
                await getResizedImage(e.logo.rawFile, 400, "PNG")
                  .then((mediaFile) => {
                    return mediaFile;
                  })
                  .catch((e) => {   
                  })
              );
              if (e._id) {
                dataWithFile.append("newLogo", e._id);
                dataWithFile.append("newLogo", "new");
              }
            } else {
            }
            delete e.logo;
          } else {
            Promise.reject("Debes llenar todos los campos de la subcategoría");
          }
        })
      );
      dataWithFile.append(
        "subCategory",
        JSON.stringify(params.data.subCategory)
      );
      return await updateDataForm(resource, dataWithFile, params.data.id);
    }
  }

  if (resource === "sos") {
    if (verb === "CREATE") {
      const dataWithFile = new FormData();
      dataWithFile.append("name", params.data.name);
      dataWithFile.append("phone", params.data.phone);
      dataWithFile.append("type", params.data.type);
      dataWithFile.append(
        "logo",
        await getResizedImage(params.data.logo.rawFile, 200, "PNG")
          .then((mediaFile) => {
            return mediaFile;
          })
          .catch((e) => {            
          })
      );

      return await createDataForm(resource, dataWithFile);
    }
    if (verb === "UPDATE") {
      const dataWithFile = new FormData();
      dataWithFile.append("name", params.data.name);
      dataWithFile.append("state", params.data.state);
      dataWithFile.append("phone", params.data.phone);
      dataWithFile.append(
        "logo",
        await getResizedImage(params.data.logo.rawFile, 200, "PNG")
          .then((mediaFile) => {
            return mediaFile;
          })
          .catch((e) => {            
          })
      );
      return await updateDataForm(resource, dataWithFile, params.data.id);
    }
  }

  if (resource === "product") {
    if (verb === "CREATE" || verb === "UPDATE") {
      const dataWithFile = objectToFormData(params.data);
      if (params.data.image?.rawFile) {
        dataWithFile.append(
          "image",
          await getResizedImage(params.data.image.rawFile, 800, "JPEG")
        );
      }

      if (verb === "CREATE") {
        return await createDataForm(resource, dataWithFile);
      }

      if (verb === "UPDATE") {
        dataWithFile.delete("provider");
        return await updateDataForm(resource, dataWithFile, params.data.id);
      }
    }
  }

  if (resource === "profile") {
    if (verb === GET_ONE) {
      const storedProfile = localStorage.getItem("profile");

      if (storedProfile) {
        return Promise.resolve({
          data: { ...JSON.parse(storedProfile), id: params.id },
        });
      }
      // No profile yet, return a default one
      // It's important that we send the same id as requested in params
      // Indeed, react-admin will verify it and may throw an error if they are different
      // I don't have to do it when the profile exists as it will be included in the data stored in the local storage
      return Promise.resolve({
        data: { id: params.id, language: "es" },
      });
    }

    if (verb === UPDATE) {
      const dataWithFile = new FormData();
      dataWithFile.append("first_name", params.data.first_name);
      dataWithFile.append("business_name", params.data.business_name);
      dataWithFile.append("phone", params.data.phone);
      dataWithFile.append("CI_NIT", params.data.CI_NIT);
      if (params.data.recommendedServices) {
        dataWithFile.append(
          "recommendedServices",
          JSON.stringify(params.data.recommendedServices)
        );
      }
      dataWithFile.append(
        "availability",
        JSON.stringify(params.data.availability)
      );
      if (params.data.picture?.rawFile?.type == "image/jpeg") {
        dataWithFile.append(
          "picture",
          await getResizedImage(params.data.picture.rawFile, 800, "JPEG")
        );
      } else if (params.data.picture?.rawFile?.type == "image/png") {
        dataWithFile.append(
          "picture",
          await getResizedImage(params.data.picture.rawFile, 800, "PNG")
        );
      }
      let profile = await updateDataForm(
        "profile",
        dataWithFile,
        params.data._id
      );
      profile.data.id = profile.data._id;
      localStorage.setItem("profile", JSON.stringify(profile.data));
      return profile;
    }
  }

  if (resource === "employee") {
    if (verb === "CREATE") {
      console.log(params)
      const dataWithFile = new FormData();
      dataWithFile.append("first_name", params.data.first_name);
      dataWithFile.append("last_name", params.data.last_name);
      dataWithFile.append("phone", params.data.phone);
      dataWithFile.append("CI_NIT", params.data.CI_NIT);
      dataWithFile.append("email", params.data.email);
      dataWithFile.append("password", params.data.password);
      if (params.data.branch) {
        dataWithFile.append("branch", JSON.stringify(params.data.branch));
      }
      dataWithFile.append("serviceMethod", params.data.serviceMethod);
      dataWithFile.append("state", true);
      dataWithFile.append(
        "picture",
        await getResizedImage(params.data.picture.rawFile, 800, "JPEG")
      );
            
      if (params.data.deliveryVehicle) {
        dataWithFile.append("deliveryVehicle", params.data.deliveryVehicle);
      }

      if (params.data.availableServices) {
        dataWithFile.append(
          "availableServices",
          JSON.stringify(params.data.availableServices)
        );
      }

      if (params.data.servicesCommissions) {
        dataWithFile.append(
          "servicesCommissiones",
          JSON.stringify(params.data.servicesCommissions)
        );
      }

      if (params.data.availability) {
        dataWithFile.append(
          "availability",
          JSON.stringify(params.data.availability)
        );
      }

      return await createDataForm("employee", dataWithFile, params.data._id);
    }

    if (verb === UPDATE) {
      const dataWithFile = new FormData();
      dataWithFile.append("first_name", params.data.first_name);
      dataWithFile.append("last_name", params.data.last_name);
      dataWithFile.append("phone", params.data.phone);
      dataWithFile.append("CI_NIT", params.data.CI_NIT);      
      dataWithFile.append("state", params.data.state);
      if (params.data.branch) {
        dataWithFile.append("branch", JSON.stringify(params.data.branch));
      }

      dataWithFile.append("serviceMethod", params.data.serviceMethod);
      if (params.data.picture?.rawFile) {
        dataWithFile.append(
          "picture",
          await getResizedImage(params.data.picture?.rawFile, 800, "JPEG")
        );
      }
      if (params.data.availableServices) {
        dataWithFile.append(
          "availableServices",
          JSON.stringify(params.data.availableServices)
        );
      }

      if (params.data.servicesCommissions) {
        dataWithFile.append(
          "servicesCommissions",
          JSON.stringify(params.data.servicesCommissions)
        );
      }

      if (params.data.availability) {
        dataWithFile.append(
          "availability",
          JSON.stringify(params.data.availability)
        );
      }

      return await updateDataForm("employee", dataWithFile, params.data._id);
    }
  }

  if (resource === "deliverysetting") {
    if (verb === "CREATE") {
      const dataWithFile = new FormData();
      Object.entries(params.data).forEach(async ([key, value]) => {
        if (key != "image") {
          dataWithFile.append(key, value);
        }
      });
      dataWithFile.append(
        "image",
        await getResizedImage(params.data.image.rawFile, 800, "PNG")
      );
      return await createDataForm("deliverysetting", dataWithFile);
    }

    if (verb === UPDATE) {
      const dataWithFile = new FormData();
      Object.entries(params.data).forEach(async ([key, value]) => {
        if (key != "image") {
          dataWithFile.append(key, value);
        }
      });
      if (params.data.image?.rawFile) {
        dataWithFile.append(
          "image",
          await getResizedImage(params.data.image?.rawFile, 800, "PNG")
        );
      }
      return await updateDataForm(
        "deliverysetting",
        dataWithFile,
        params.data._id
      );
    }
  }

  if (resource === "availabilityWeb") {
    if (verb === "UPDATE") {
      delete params.data.picture;
      return await update(resource, params);
    }
  }

  if (resource === "service") {
    if (verb === "CREATE" || verb === "UPDATE") {
      const dataWithFile = objectToFormData(params.data);
      if (params.data.imageURL?.rawFile) {
        dataWithFile.append(
          "image",
          await getResizedImage(params.data.imageURL.rawFile, 800, "JPEG")
        );
      }

      if (verb === "CREATE") {
        return await createDataForm(resource, dataWithFile);
      }

      if (verb === "UPDATE") {
        dataWithFile.delete("provider");
        dataWithFile.delete("category");
        dataWithFile.delete("subCategory");
        return await updateDataForm(resource, dataWithFile, params.data.id);
      }
    }
  }

  if (resource === "bookingBalance") {
    resource = "booking/balance";
  }

  if (resource === "adminBookingBalance") {
    if (verb === "GET_ONE") {
      resource = "booking";
    } else {
      resource = "admin/booking/balance";
    }
    if (verb === "UPDATE") {
      params.meta = {
        ...params.meta,
        customActsion: "payFee",
      };
    }
  }
  console.log(verb);
  if (resource === "availability") {
    if (verb === "GET_ONE") {
      if (params.meta?.branch) {
        params.id = params.id + "?branch=" + params.meta.branch;
        return getOne(resource, params);
      } else return getOne(resource, params);
    }
  }
  if (verb === "GET_LIST") return await getList(resource, params);
  if (verb === "GET_ONE") return getOne(resource, params);
  if (verb === "GET_MANY") return getMany(resource, params);
  if (verb === "CREATE") return create(resource, params);
  if (verb === "UPDATE") return await update(resource, params);
  if (verb === "createMany") return createMany(resource, params);
  if (verb === "DELETE") {
    return _delete(resource, params);
  }
};

export default handler;
