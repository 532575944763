import { useState } from "react";
import { useDataProvider } from "react-admin";

export const useExportBalance = () => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const normalizeFilters = (filters) => {
    const normalized = {
      // bookingDate:
      //   filters["bookingDate>"] || filters["bookingDate<"]
      //     ? {
      //         from: filters["bookingDate>"],
      //         to: filters["bookingDate<"],
      //       }
      //     : undefined,      
      "bookingDate>": filters["bookingDate>"] ? filters["bookingDate>"] : undefined,
      "bookingDate<": filters["bookingDate<"] ? filters["bookingDate<"] : undefined,
      "branch._id": filters["branch._id"] ? filters["branch._id"] : undefined,      
      "employee._id": filters["employee._id"] ? filters["employee._id"] : undefined,
    };
    
    return Object.fromEntries(Object.entries(normalized).filter(([_, value]) => value !== undefined));
  };



  const getExportData = async (filters = {}) => {
    setLoading(true);    

    const normalizedFilters= normalizeFilters(filters);

    try {
      console.log("Filtros use:", normalizedFilters);      
      const [ingresos, otrosIngresos, egresos, otrosEgresos] = await Promise.all([
        dataProvider.getList("booking/balance", { pagination: { page: 1, perPage: 10000 }, sort: { field: "bookingDate", order: "DESC" }, filter: normalizedFilters }),
        dataProvider.getList("transaction/provider/income", { pagination: { page: 1, perPage: 10000 }, sort: { field: "date", order: "DESC" }, filter: normalizedFilters }),
        dataProvider.getList("booking/balance", { pagination: { page: 1, perPage: 10000 }, sort: { field: "bookingDate", order: "DESC" }, filter: normalizedFilters }),
        dataProvider.getList("transaction/provider/expense", { pagination: { page: 1, perPage: 10000 }, sort: { field: "date", order: "DESC" }, filter: normalizedFilters }),
      ]);

      const ingresosData = ingresos.data.map((record) => ({
        Fecha: record.bookingDate,        
        Monto: record.paymentInfo?.totalPrice || record.amount,
        Empleado: record.employee?.fullName,
        Sucursal: record.branch?.name,
        EstadoPago: record.paymentInfo?.status || "",
      }));

      const otrosIngresosData = otrosIngresos.data.map((record) => ({
        Fecha: record.date,
        Descripción: record.description,
        Monto: record.amount,
        Empleado: record.employee?.fullName,
        Sucursal: record.branch?.name,
      }));

      const egresosData = egresos.data.map((record) => ({
        Fecha: record.bookingDate,        
        Monto: record.paymentInfo?.totalPrice || record.amount,
        Empleado: record.employee?.fullName,
        Sucursal: record.branch?.name,
        ComisionEmpleado: record.paymentInfo?.feeAmountEmployee,
        Comisiones: record.paymentInfo?.feeAmount,
        EstadoComision: record.paymentInfo?.isFeePaid ? "Pagada" : "Pendiente",
      }));

      const otrosEgresosData = otrosEgresos.data.map((record) => ({
        Fecha: record.date,
        Descripción: record.description,
        Monto: record.amount,
        Empleado: record.employee?.fullName,
        Sucursal: record.branch?.name,
      }));

      setData({ ingresosData, otrosIngresosData, egresosData, otrosEgresosData });
    } catch (error) {
      console.error("Error al obtener los datos para exportar:", error);
    } finally {
      setLoading(false);
    }
  };

  return { getExportData, data, loading, setData };
};
