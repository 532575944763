import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Edit as BaseEdit,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  email,
  DateInput,
  SelectInput,
  Toolbar,
  SaveButton,
  DeleteButton,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import Map from "../../components/Map";
import countryCodes from "./phone_code.json";


const PREFIX = "Edit";

const classes = {
  inlineBlock: `${PREFIX}-inlineBlock`,
};

const StyledBaseEdit = styled(BaseEdit)({
  [`& .${classes.inlineBlock}`]: {
    display: "inline-flex",
    marginRight: "1rem",
  },
});

// CustomToolbar con los botones de Guardar y Eliminar
const CustomToolbar = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const handleDeleteSuccess = () => {
    notify("Eliminado con éxito", { type: "success" });
    redirect("enterprise/customers"); // Redirecciona a la lista de clientes o la ruta correspondiente
    refresh();
  };

  const handleDeleteFailure = (error) => {
    notify(`Error: ${error.message}`, { type: "warning" });
  };

  return (
    <Toolbar {...props} style={{ display: "flex", justifyContent: "space-between" }}>
      <SaveButton />
      <DeleteButton
        mutationMode="pessimistic"
        onSuccess={handleDeleteSuccess}
        onFailure={handleDeleteFailure}
        confirmTitle="Confirmación de eliminación"
        confirmContent="¿Estás seguro de que deseas eliminar este registro? Esta acción no se puede deshacer."
      />
    </Toolbar>
  );
};


export const Edit = ({ ...props }) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState("591");

  const handleCountryChange = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  return (
  <StyledBaseEdit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput validate={[required()]} source="fullName" label="Nombre Completo" />
      <SelectInput
        label="Código de País"
        source="countryCode"
        choices={countryCodes.paises}
        optionText={(value) => `${value.name} ( +${value.dial_code} )`}
        optionValue="dial_code"
        onChange={handleCountryChange}
        validate={[required()]}
        defaultValue={countryCodes.paises.find((e) => e.code === "BO")?.dial_code}
      />
      <NumberInput source="phone" label="Teléfono/Celular" />
      <TextInput validate={[email()]} source="email" label="Email" />
      <DateInput label="Fecha de Nacimiento" source="birthdate" />
      <SelectInput
        label="Género"
        source="gender"
        choices={[
          { id: "hombre", name: "Hombre" },
          { id: "mujer", name: "Mujer" },
        ]}
      />
      <ArrayInput source="addresses" label="Direcciones">
        <SimpleFormIterator>
          <Map />
          <TextInput validate={[required()]} source="name" label="Nombre" />
          <TextInput validate={[required()]} source="street" label="Calle" />
          <TextInput source="house" label="Número de casa" />
          <TextInput source="reference" label="Referencia" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </StyledBaseEdit>
)};
